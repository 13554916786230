import styled from 'styled-components';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';
import { GREYS } from 'UI/globals/colours';
import { CloseIcon } from 'UI/globals/ModalCloseIcon';

export const HMI = styled.span`
  color: ${GREYS.white};
  display: none;
  margin-left: 12px;
  @media (max-width: ${BREAKPOINT_S}) {
    display: block;
    font-size: 20px;
    margin-right: 16px;
    cursor: pointer;
  }
`;

export const Close = styled(CloseIcon)`
  margin-bottom: 6px;
  font-size: 12px;
  padding-top: 2px;
  width: 17px;
  height: 17px;
  border-radius: 5px;
  margin-top: 5px;
`;
