import React, { useState, useEffect } from 'react';
import { HMI, Close } from 'UI/apps/ToggleMenuApp/ToggleMenuApp';

const ToggleMenuAplications = () => {
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    if (toggle) {
      const element = document.getElementById('topheader-midd');
      element.style.display = 'flex';
      element?.classList?.add('topheader-Mobile');
      setToggle(true);
    } else {
      const element = document.getElementById('topheader-midd');
      element?.classList?.remove('topheader-Mobile');
    }
  }, [toggle]);
  return (
    <>
      {toggle ? (
        <HMI onClick={() => setToggle(false)} id="hamburger">
          <Close />
        </HMI>
      ) : (
        <HMI onClick={() => setToggle(true)} id="hamburger">
          &#9776;
        </HMI>
      )}
    </>
  );
};
export default ToggleMenuAplications;
