import React from 'react';
import ReactDOM from 'react-dom';
import ToggleMenuAplications from './component/core__ToggleMenuApp-react';

class ToggleMenuApp {
  constructor(properties) {
    Object.assign(this, properties);

    if (this.nodeSSR) return;

    this.start();
  }

  start() {
    const ReactInstance = this.getReactApp();
    const jsx = <ReactInstance appConfig={this.appConfig} />;

    ReactDOM['render'](jsx, this.appSection);
  }

  getReactApp() {
    return ToggleMenuAplications;
  }
}
export default ToggleMenuApp;
